import React from 'react'
import {Route,Routes,useLocation} from 'react-router-dom'
import About from './About'
import Kkk from './Kkk'
import Login from './Login'
import { AnimatePresence } from 'framer-motion'
import Websitedevelopment from './Websitedevelopment'
import Socialmediamarketing from './Socialmediamarketing'
import Innovation from './Innovation'
export default function AnimatedRoutes() {
  const location=useLocation();
  return (
    <AnimatePresence>
    <Routes location={location} key={location.pathname}>
      
      <Route exact path='/'  ></Route>
      <Route exact path='/about' element={<About/>} ></Route>
      <Route exact path='/websitedevelopment' element={<Websitedevelopment/>} ></Route>
      <Route exact path='/socialmediamarketing' element={<Socialmediamarketing/>} ></Route>
      <Route exact path='/innovation' element={<Innovation/>} ></Route>
      <Route exact path='/searchengineoptimization' element={<Login/>} ></Route>
    
      </Routes>
      </AnimatePresence>
  )
}
