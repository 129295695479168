// import React from 'react'
// import {Link,useLocation} from 'react-router-dom'

// export default function Navbarnew() {
  



//   return (<>
//     <nav className="navbar" data-bs-theme="dark"  style={{margin:"0px",padding:"0px",left:"0",top:"0" }}>
//   <div className="container-fluid gy-0 " style={{backgroundColor:"#F070A1",color:"black",height:"100%", paddingLeft:"5%",paddingRight:"5%",fontFamily:"Montserrat"}}>
//     <Link className="navbar-brand hovernavbar" id="Home" to="/">Home</Link>
//     <Link className="navbar-brand hovernavbar"  id="About" to="/About">About Us</Link>
//     <Link className="navbar-brand hovernavbar"  id="websitedevelopment" to="/websitedevelopment">Website Development</Link>
//     <Link className="navbar-brand hovernavbar" id="socialmediamarketing"  to="/socialmediamarketing">Social Media Marketing</Link>
//     <Link className="navbar-brand hovernavbar" id="searchengineoptimization"  to="/searchengineoptimization">Search Engine Optimization</Link>
//     <Link className="navbar-brand hovernavbar" id="innovation"  to="/innovation">Innovation</Link>
//   </div>
// </nav>
//     </>)
// }



import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Navbarnew() {
  const kkk=({isActive})=>{

    return{
      backgroundColor:isActive?"#10E7DC":"#F070A1",
      padding:"8px",
      borderRadius:"10px",
      color:isActive?"black":"white"
    }
    
  }
  return (
    <nav className="navbar" data-bs-theme="dark" style={{ margin: "0px", padding: "0px", left: "0", top: "0" }}>
      <div className="container-fluid gy-0 gx-0" style={{  height: "100%", paddingLeft: "5%",paddingRight: "5%", fontFamily: "Montserrat" }}>
        <NavLink style={kkk}  className="navbar-brand hovernavbar" id="Home" to="/" activeClassName="active-link ">Home</NavLink>
        <NavLink style={kkk} className="navbar-brand hovernavbar" id="About" to="/About" activeClassName="active-link">About Us</NavLink>
        <NavLink style={kkk} className="navbar-brand hovernavbar" id="websitedevelopment" to="/websitedevelopment" activeClassName="active-link">Website Development</NavLink>
        <NavLink style={kkk} className="navbar-brand hovernavbar" id="socialmediamarketing" to="/socialmediamarketing" activeClassName="active-link">Social Media Marketing</NavLink>
        <NavLink style={kkk} className="navbar-brand hovernavbar" id="searchengineoptimization" to="/searchengineoptimization" activeClassName="active-link">Search Engine Optimization</NavLink>
        <NavLink style={kkk} className="navbar-brand hovernavbar" id="innovation" to="/innovation" activeClassName="active-link">Innovation</NavLink>
      </div>
    </nav>
  );
}
