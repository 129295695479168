import React, { useState } from 'react';


const Navbar = () => {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };

  return (
    <nav className="navbar">
      <a 
        href="#"
        className={`navbar-link ${hovered ? 'hovered' : ''}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        Hover Me
      </a>
    </nav>
  );
};

export default Navbar;
