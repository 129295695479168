import React from 'react'
import {motion} from 'framer-motion'
import { useLocation } from 'react-router-dom'
export default function About() {

  
  return (<>
    <motion.div  initial={{height:"0px"}} animate={{height:"300px",transitionDuration:"0.4s",TransitionTimingFunction:"ease-in"}} exit={{opacity:1,height:"0px",display:"none"}} className='Navbarbullets fontclass' ><p ><h1 style={{display:"flex", justifyContent:"center"}}>BringMeOnline Digital Services</h1><br></br><h3>
    BringMeOnline Digital Services is a cutting-edge firm that specializes in providing comprehensive services in Website Development and Social Media Marketing along with Search Engine Optimization. Established with a vision to empower businesses in the digital landscape, our team of skilled professionals is committed to delivering innovative solutions that drive online success.</h3>
    </p></motion.div>
    </>)
}
