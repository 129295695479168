import React,{useEffect} from 'react'

export default function Features() {

    useEffect(() => {
      const myimg = document.getElementsByClassName("myimgscroll");
      
      const fixeddiv = document.getElementById("fixed-div");
      const myscrollfnc = () => {
          const scrollcnt = document.getElementById("scrollcnt");
          for (let i = 0; i < myimg.length; i++) {
            myimg[i].style.transform = `translateX(-${scrollcnt.scrollTop}px)`;
            // myimg[i].style.transition = "1s ";
          }
          console.log("scroll done");
        }
    

        scrollcnt.addEventListener("scroll", myscrollfnc);
        const scrollrightfnc=(e)=>{
          e.preventDefault();
        }
        fixeddiv.addEventListener("scroll",scrollrightfnc)
    
        return () => {
          scrollcnt.removeEventListener("scroll", myscrollfnc);
        };
      }, []);
    
  return (<>
  <div className='fontclass ourfeaturesclass' style={{color:"black"}}><h2>Our Features</h2></div>
   <div className="parent-container" style={{marginTop:"0px",padding:"0"}}  id="scrollcnt">
      <div className="fixed-div-wrapper"  >
        <div className="fixed-div fontclass" id="fixed-div" style={{display:"flex",color:"black"}}>
        <div className='myimgscroll' ><div ><h2>NVMe Storage</h2><p>NVMe (Non-Volatile Memory Express) storage accelerates website building with its unparalleled speed and reliability. Leveraging PCIe interface, NVMe SSDs deliver faster data transfer rates, reducing website loading times and enhancing user experience. </p></div></div>
        <div className='myimgscroll' ><div><h2 >Unlimited Free SSL</h2><p>SSL (Secure Sockets Layer) is vital in website building for encrypting data transmitted between web servers and browsers, ensuring confidentiality and integrity. It establishes a secure connection, preventing unauthorized access and data interception, enhancing trust and security for users.</p></div></div>
        <div className='myimgscroll' ><div><h2 >Unlimited Free Bandwidth</h2><p>Bandwidth in website building enables seamless data transfer, ensuring fast loading times and uninterrupted access to content. With unlimited bandwidth, websites can handle high traffic volumes without concerns about exceeding data limits or incurring additional charges.</p></div></div>
        <div className='myimgscroll' ><div><h2 >Free EmailId Creation</h2><p>Official email IDs play a pivotal role in establishing professionalism, credibility, and brand identity. They serve as primary communication channels for reaching out to prospects, clients, and partners, facilitating seamless correspondence for sales pitches, proposals, and negotiations. </p></div></div>
        <div className='myimgscroll' ><div><h2 >Free CDN</h2><p>CDNs (Content Delivery Networks) by distributing website content across multiple servers located strategically worldwide, CDNs reduce latency and accelerate content delivery to users regardless of their geographic location. This results in faster page load times, enhanced user experience, and improved SEO rankings.</p></div></div>
        <div className='myimgscroll' ><div><h2 >Enhanced DDoS Protection</h2><p>DDoS (Distributed Denial of Service) protection mechanisms, such as traffic filtering, rate limiting, and behavioral analysis, help mitigate the impact of such attacks by identifying and blocking malicious traffic while allowing legitimate users to access the website. </p></div></div>
        <div className='myimgscroll' ><div><h2 >Web Application Firewall</h2><p>A Web Application Firewall (WAF) is critical for website security, protecting against various cyber threats such as SQL injection, cross-site scripting, and other attacks. It monitors and filters incoming HTTP traffic, blocking malicious requests and safeguarding web applications from exploitation, data breaches, and unauthorized access.</p></div></div>
        <div className='myimgscroll' ><div><h2 >Cloudflare Protected Nameservers</h2><p>It enhance website security by safeguarding against DNS (Domain Name System) attacks, ensuring uninterrupted access to web services. By leveraging Cloudflare's robust infrastructure and DDoS mitigation capabilities, businesses can protect their online presence, maintain uptime, and safeguard against potential disruptions or data breaches.</p></div></div>
        <div className='myimgscroll' ><div><h2 >Free Malware Scanner</h2><p>Malware scanners are crucial for website security, detecting and removing malicious software that can compromise data, damage reputation, and disrupt operations. By regularly scanning for malware, websites can proactively identify and mitigate security threats, ensuring a safe and trustworthy online experience for visitors and users.</p></div></div>
        <div className='myimgscroll' ><div><h2 >Domain WHOIS Protection</h2><p>Domain WHOIS Privacy Protection shields website owners' personal information from being publicly accessible in the WHOIS database. It safeguards against spam, identity theft, and unsolicited contact while maintaining confidentiality. This ensures privacy and security, fostering trust between website owners and their users.</p></div></div>
        <div className='myimgscroll' ><div><h2 >Free Logo Creation</h2><p>A brand logo serves as a visual representation of a company's identity, values, and offerings. It creates immediate recognition and fosters brand loyalty. A well-designed logo conveys professionalism, trustworthiness, and uniqueness, distinguishing the company from competitors and leaving a lasting impression on customers. </p></div></div>
        <div className='myimgscroll' ><div><h2 >Free Content Writing</h2><p>Content writing is crucial for a website as it communicates the brand's message, engages visitors, and drives conversions. High-quality content enhances SEO, attracting organic traffic and improving search engine rankings. We provide this service as complementry.</p></div></div>
        <div className='myimgscroll' ><div><h2 >SEO Ready Architecture</h2><p>We create the website in consideration with Search Engine Optimization so in future there is no need to change the architecture of the webiste and SEO can easily being implimented if required. Also by using this SEO ready technique , the website automatically get indexed in the search engine result pages without bearing the cost of SEO </p></div></div>
        <div className='myimgscroll' ><div><h2 >Custom Coded Design</h2><p>Our each website is custom coded and is tailored made to cope up with the requirements of the customers , their product and targeted audiance. We don't use the word press and other platforms to built the website. We believe in delivering the best user experience by utilising the power of javascript in a website. </p></div></div>
        </div>


      </div>
      <div style={{ height: '0px', margin: '0', padding: '0' }}></div> {/* Adding extra content to make the parent container scrollable */}
    </div>
   </>)
}
