import React from 'react'
import {motion} from 'framer-motion'

export default function Innovation() {
  return (
    <motion.div  initial={{height:"0px"}} animate={{height:"300px",transitionDuration:"0.4s",TransitionTimingFunction:"ease-in"}} exit={{opacity:1,height:"0px",display:"none"}} className='Navbarbullets fontclass' ><p ><h1 style={{display:"flex", justifyContent:"center"}}>Innovation and Adaptability</h1><br></br><h3>
    The digital landscape is ever-evolving, and we thrive on staying ahead of the curve. Our team continuously explores new technologies, design trends, and marketing strategies to provide our clients with innovative solutions. We are agile and adaptable, ensuring that our clients always have a competitive edge in the dynamic online environment.</h3>
    </p></motion.div>
  )
}
